
import * as lottieData from "@/assets/lottie/seed.json";
import Lottie from "@/components/lottie/Lottie.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: { Lottie },
  data() {
    return {
      anim: null,
      lottieOptions: { animationData: lottieData }
    };
  },
  methods: {
    handleAnimation: function(anim: any) {
      this.anim = anim;
    }
  }
});

<template>
  <router-view />
</template>

<style lang="scss">
@import "./src/assets/scss/main.scss";

#app {
  display: flex;
  min-height: 100vh;
}
</style>
